<template>
    <div>
        <div class="row mb-3 d-flex justify-content-center">
            <div class="col-md-3 text-left text-md-right">
                <strong>
                    {{ $t("message.checkout.delivery-address") }}
                </strong>
            </div>
            <div class="col-md-7">
                <h4>{{ name }}</h4>
                <p class="mb-1">{{ addressLine1 }}</p>
                <p class="mb-1">{{ addressLine2 }}</p>
                <p class="mb-1">
                    <span v-if="postcode">{{ postcode }},</span>
                    <span>{{ country[$i18n.locale] }}</span>
                </p>
            </div>
            <div class="col-md-2 text-right">
                <router-link class="btn btn-sm btn-outline-dark"
                             to="/checkout/products/delivery">
                    {{ $t("message.checkout.edit") }}
                </router-link>
            </div>
        </div>

        <div class="row mb-3 d-flex justify-content-center">
            <div class="col-md-3 text-left text-md-right">
                <strong>
                    {{ $t("message.checkout.delivery-method") }}
                </strong>
            </div>
            <div class="col-md-7">
                <h5>
                    {{ delivery.deliveryType === 'standard' ? $t("message.checkout.delivery-standard") : "" }}
                </h5>
            </div>
            <div class="col-md-2 text-right">
                <router-link class="btn btn-sm btn-outline-dark"
                             to="/checkout/products/delivery">
                    {{ $t("message.checkout.edit") }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderDetailsDelivery",
    props: {
        delivery: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        address () {
            return this.delivery.address;
        },
        name () {
            return `${ this.address.firstName } ${ this.address.lastName }`;
        },
        addressLine1 () {
            return this.address?.addressLine1 ?? "";
        },
        addressLine2 () {
            return this.address?.addressLine2 ?? "";
        },
        postcode () {
            return this.address?.postcode ?? "";
        },
        country () {
            return this.address.country.name;
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
