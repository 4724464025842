export function calculateDeliveryCost (
    deliveryClasses,
    address,
    itemTotal
) {
    const defaultClass = deliveryClasses
        .find(
            deliveryClass => deliveryClass.default === true
        );
    const target = deliveryClasses
            .find(
                ({ countries }) => countries
                    .map(({ _id }) => _id)
                    .includes(address?.country?._id)
            ) ??
        defaultClass;
    if (target) {
        if (target.conditions[0].sign === ">") {
            if (target.conditions[0].includeEqual) {
                if (itemTotal >= target.conditions[0].value) {
                    return 0;
                } else {
                    return target.methods[0].price.value;
                }
            } else {
                if (itemTotal > target.conditions[0].value) {
                    return 0;
                } else {
                    return target.methods[0].price.value;
                }
            }
        }
    }
    return 0;
}

export default calculateDeliveryCost;
