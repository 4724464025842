<template>
    <div class="row mb-3 d-flex justify-content-center">
        <div class="col-md-3 text-left text-md-right">
            <strong>
                {{ $t("message.checkout.contact") }}
            </strong>
        </div>
        <div class="col-md-7">
            <p class="mb-0">
                {{ email }}
            </p>
            <p class="mb-0">
                {{ phone }}
            </p>
        </div>
        <div class="col-md-2 text-right">
            <router-link class="btn btn-sm btn-outline-dark"
                         v-if="isTreatment"
                         to="/checkout/treatments/delivery">
                {{ $t("message.checkout.edit") }}
            </router-link>
            <router-link class="btn btn-sm btn-outline-dark"
                         v-else
                         to="/checkout/products/delivery">
                {{ $t("message.checkout.edit") }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderDetailsContact",
    props: {
        isTreatment: {
            type: Boolean,
            default: false,
        },
        delivery: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        email () {
            return this.delivery?.email ?? "";
        },
        phone () {
            return this.delivery?.phone ?? "";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
