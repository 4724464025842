<template>
    <section class="checkout-confirmation">
        <div class="container pt-4">
            <order-details-contact v-bind:is-treatment="type === 'treatments'"
                                   v-bind:delivery="cartOptions.delivery">
            </order-details-contact>

            <order-details-delivery v-if="type === 'products'"
                                    v-bind:delivery="cartOptions.delivery">
            </order-details-delivery>

            <ul class="list-unstyled">
                <li class="media py-3 align-items-stretch"
                    v-for="product in cart"
                    v-bind:key="product._id">
                    <div class="item-image-wrapper mr-2"
                         v-if="type === 'products'">
                        <img class="d-block mx-auto item-image"
                             v-if="type === 'products'"
                             v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ product.images[0] }`"
                             v-bind:alt="`${ product.title[$i18n.locale] } | ${ product.subtitle[$i18n.locale] }`">
                    </div>
                    <div class="media-body">
                        <div class="row no-gutters h-100 position-relative">
                            <div class="col-md-8 d-flex flex-column justify-content-center">
                                <h5 class="mt-0 mb-1" v-html="product.title[$i18n.locale]"></h5>
                                <p class="mb-0"
                                   v-if="type === 'products'"
                                   v-html="product.subtitle[$i18n.locale]"></p>
                                <div class="mt-2"
                                     v-if="type === 'products'">
                                    <p class="d-inline-block">
                                        {{ $t("message.basket.unit-price") }}
                                    </p>
                                    <h5 class="d-inline-block">
                                        <small>HK$</small>{{ product.prices[0].hkd | currency("", 2) }}
                                    </h5>
                                </div>
                                <template v-else>
                                    <div class="mt-2">
                                        <p class="mb-1 d-inline-block mr-1">
                                            {{ $t("message.basket.treatments.options") }}
                                        </p>
                                        <h5 class="d-inline-block">
                                            <span class="badge badge-dark font-weight-light mr-2"
                                                  v-for="sku in product.skus"
                                                  v-bind:key="sku._id">
                                                {{ sku.name[$i18n.locale] }}
                                            </span>
                                        </h5>
                                    </div>
                                    <div class="mb-1">
                                        <p class="d-inline-block mb-0 mr-1">
                                            {{ $t("message.basket.treatments.date") }}
                                        </p>
                                        <h5 class="d-inline-block mb-0">
                                            {{ product.session }} {{ product.date | dateFnsFormatter("yyyy/M/d") }}
                                        </h5>
                                    </div>
                                    <div class="mb-1">
                                        <p class="d-inline-block mb-0 mr-1">
                                            {{ $t("message.basket.treatments.branch") }}
                                        </p>
                                        <h5 class="d-inline-block mb-0">
                                            {{ product.branch.address[$i18n.locale] }}
                                        </h5>
                                    </div>
                                </template>
                            </div>
                            <div class="col-md-4 d-flex flex-column">
                                <div class="flex-2 d-flex flex-column justify-content-center align-items-end align-items-md-center mb-1 mb-md-0"
                                     v-if="type === 'products'">
                                    <p class="mb-1 mr-1">
                                        {{ $t("message.basket.quantity") }}
                                    </p>
                                    <div class="input-group input-group-sm d-flex justify-content-center font-weight-bold" style="max-width: 120px; font-size: 20px;">
                                        {{ product.quantity }}
                                    </div>
                                </div>
                                <div class="flex-2 d-flex flex-column justify-content-center align-items-end align-items-md-center mb-1 mb-md-0">
                                    <p class="mb-0 mr-1">
                                        {{ $t("message.basket.sub-total") }}
                                    </p>

                                    <h5 class="mb-0" v-if="type === 'products'">
                                        <small>HK$</small>{{ product.quantity * product.prices[0].hkd | currency("", 2) }}
                                    </h5>
                                    <h5 class="mb-0" v-else>
                                        <small>HK$</small>{{ product.skus.reduce((acc, sku) => acc += sku.hkd, 0) | currency("", 2) }}
                                    </h5>
                                </div>
                                <div class="flex-1 text-center d-flex justify-content-end justify-content-md-center align-items-center">
                                    <button class="btn btn-sm btn-outline-dark"
                                            v-on:click="deleteItemFromCart('products', product)">
                                        {{ $t("message.basket.delete") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="row no-gutters mb-5">
                <div class="col-md-9"></div>
                <div class="col-md-3">
                    <table>
                        <tr v-if="type === 'products'">
                            <td class="text-right">
                                <p class="d-inline-block mb-0 mr-3">
                                    {{ $t("message.basket.delivery") }}
                                </p>
                            </td>
                            <td class="text-left">
                                <h5 class="d-inline-block mb-0">
                                    <small>HK$</small>{{ deliveryTotal | currency("", 2) }}
                                </h5>
                            </td>
                        </tr>
                        <tr v-if="isCouponEligible">
                            <td class="text-right">
                                <p class="d-inline-block mb-0 mr-3">
                                    {{ $t("message.basket.promotion") }}
                                </p>
                            </td>
                            <td class="text-left">
                                <h5 class="d-inline-block text-danger text-nowrap mb-0">
                                    -<small>HK$</small>{{ discountTotal | currency("", 2) }}
                                </h5>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-right">
                                <p class="d-inline-block mb-0 mr-3">
                                    {{ $t("message.basket.total") }}
                                </p>
                            </td>
                            <td class="text-left">
                                <h5 class="d-inline-block mb-0">
                                    <small>HK$</small>{{ orderTotal | currency("", 2) }}
                                </h5>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="d-flex justify-content-center mb-3">
                <b-form-checkbox v-model="acceptTC">
                    {{ $t("message.checkout.accept-tc-1") }}
                    <router-link to="/terms">{{ $t("message.checkout.accept-tc-2") }}</router-link>
                    {{ $t("message.checkout.accept-tc-3") }}
                </b-form-checkbox>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <button class="btn btn-dark btn-block btn-confirm"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-bind:disabled="!acceptTC || cart.length === 0"
                        v-on:click="confirm">
                    {{ $t("message.checkout.confirm-order") }}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import isCouponEligible from "../utilities/isCouponEligible";
import calculateDeductibleByCoupon from "../utilities/calculateDeductibleByCoupon";
import calculateDeliveryCost from "../utilities/calculateDeliveryCost";

import {
    BFormCheckbox,
} from "bootstrap-vue";
import OrderDetailsContact from "./OrderDetails/Contact";
import OrderDetailsDelivery from "./OrderDetails/Delivery";

export default {
    name: "OrderDetails",
    components: {
        OrderDetailsDelivery,
        OrderDetailsContact,
        BFormCheckbox,
    },
    data () {
        return {
            isLoading: false,
            acceptTC: false
        }
    },
    props: {
        type: {
            type: String,
        },
    },
    computed: {
        orders () {
            return this.type === "treatments" ?
                this.$store.getters["mall/salonOrders"] :
                this.$store.getters["mall/shopOrders"];
        },
        deliveryClasses () {
            return this.$store.state.mall.shop.deliveryClasses;
        },
        coupons () {
            return this.type === "products" ?
                this.$store.state.cart.coupon.products :
                this.$store.state.cart.coupon.treatments;
        },
        coupon () {
            return this.coupons?.[0] ?? null;
        },
        isCouponEligible () {
            return this.coupon ?
                isCouponEligible(
                    this.cart,
                    this.itemTotal,
                    this.coupon,
                    this.orders
                ) :
                false;
        },
        cartOptions () {
            return this.$store.state.wallet.paymentSession;
        },
        cart () {
            return this.$store.state.cart.cart[this.type];
        },
        itemTotal () {
            return this.type === "treatments" ?
                this.cart.reduce(
                    (acc, treatment) => acc += treatment.prices[0].hkd * treatment.quantity, 0
                ) :
                this.cart.reduce(
                    (acc, product) => acc += product.prices[0].hkd * product.quantity, 0
                );
        },
        deliveryTotal () {
            if (this.cartOptions?.delivery?.address) {
                return calculateDeliveryCost(
                    this.deliveryClasses,
                    this.cartOptions?.delivery?.address,
                    this.itemTotal,
                );
            }
            return 0;
        },
        discountTotal () {
            if (this.isCouponEligible === false) {
                return 0;
            }
            return this.coupon ?
                calculateDeductibleByCoupon(
                    this.cart,
                    this.itemTotal,
                    this.coupon,
                ) :
                0;
        },
        orderTotal () {
            return this.itemTotal + this.deliveryTotal - this.discountTotal;
        },
    },
    methods: {
        edit (target) {
            this.$router.push(target);
        },

        deleteItemFromCart () {

        },

        async confirm () {
            this.isLoading = true;
            let order;
            try {
                order = await this.$store
                    .dispatch(
                        `mall/${ this.type === "products" ? "createShopOrder" : "createSalonOrder" }`,
                        {
                            "items": this.cart,
                            "itemTotal": this.itemTotal.toFixed(2),
                            "deliveryTotal": this.deliveryTotal.toFixed(2),
                            "discountTotal": this.discountTotal.toFixed(2),
                            "orderTotal": this.orderTotal.toFixed(2),
                            "coupons": this.coupons,
                            ...this.cartOptions
                        }
                    );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.$store.dispatch("wallet/clearPaymentSession");
            this.$store.dispatch(
                "cart/clearCart",
                this.type
            );
            this.$router.push(
                {
                    name: "checkout-payment",
                    params: {
                        "orderId": order._id
                    }
                }
            );
        },
    },
    mounted () {
        if (this.cart) {
            if (this.cart.length === 0) {
                this.$router.push("/basket");
            }
        }
    },
    watch: {

    }
}
</script>

<style lang="scss" scoped>
.checkout-confirmation {
    ul {
        & > li {
            border-bottom: 1px solid rgba(0, 0, 0, .125);

            &:first-child {
                border-top: 1px solid rgba(0, 0, 0, .125);
            }
        }
    }

    .btn-confirm {
        max-width: 300px;
    }

    .item-image-wrapper {
        min-width: 120px;

        & > .item-image {
            height: 150px;
            max-width: 100%;
        }
    }
}
</style>
